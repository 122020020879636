<script setup lang="ts">
import { inject, type Ref } from 'vue';
import BaseButton from '@/components/BaseButton/BaseButton.vue';
import BaseHeading from '@/components/BaseHeading/BaseHeading.vue';
import OnboardingBaseAsideLayout from '@/components/Onboarding/OnboardingBaseAsideLayout/OnboardingBaseAsideLayout.vue';
import BaseDialog from '@/components/BaseDialog/BaseDialog.vue';
import BaseProse from '@/components/BaseProse/BaseProse.vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();

const testRequestMarkdown = `\`\`\`powershell
  curl -H "x-lunar-api-key: ${userStore.apiKey}" \\
       -H "x-lunar-scheme: https" \\
       -H "x-lunar-host: httpbin.org" \\
       -w "\\nHTTP Code: %{http_code}\\n" \\
       https://hosted-gateway.lunar.dev/anything/hello-world
  \`\`\``;

const open = inject<Ref<boolean>>('dialogIsOpen');

const emit = defineEmits<{
  next: [value: string];
}>();

const onNext = () => {
  emit('next', 'OnboardingSetupComplete');
};

function onClose() {
  if (open) {
    open.value = false;
  } else {
    console.error('open is undefined or not provided.');
  }
}
</script>

<template>
  <div class="onboarding-saas-test-request">
    <BaseDialog noPadding :open="open" closeable @close="onClose">
      <OnboardingBaseAsideLayout>
        <template #aside>
          <div class="lu-flex lu-flex-col h-full">
            <BaseHeading> Try Your First API Call </BaseHeading>
            <p>
              Run a test request to see it in action and ensure everything is
              set up correctly.
            </p>
          </div>
        </template>

        <div
          class="lu-flex lu-flex-col lu-gap-small onboarding-saas-test-request__body"
        >
          <div class="onboarding-self-hosted-test-request-intro">
            <BaseHeading>Send a test request</BaseHeading>
            <p>Run the following command</p>

            <BaseProse
              size="small"
              variant="secondary"
              :markdown="testRequestMarkdown"
            >
              <template #default="{ markdownToHtml }">
                <div v-html="markdownToHtml" />
              </template>
            </BaseProse>
          </div>
        </div>

        <template #actions>
          <div class="ml-auto">
            <BaseButton class="font-weight-bold" @click="onNext">
              Done
            </BaseButton>
          </div>
        </template>
      </OnboardingBaseAsideLayout>
    </BaseDialog>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/styles/utils' as *;

.onboarding-saas-test-request {
  $self: &;

  &__body {
    width: 500px;
  }
}
</style>

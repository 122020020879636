<template>
  <div
    v-if="isVisible && !isCurrentScenarioFinished && currentElement"
    class="overlay"
    :style="clipPath"
  >
    <div
      class="excluded-block"
      @click.stop.prevent
      :style="{
        top: `${currentElement.top}px`,
        left: `${currentElement.left}px`,
        width: `${currentElement.width}px`,
        height: `${currentElement.height}px`,
      }"
    ></div>
    <div v-if="currentElement" class="popup" :style="popupStyle">
      <span>{{ items[currentStep].title }}</span>
      <div class="popup__actions">
        <BaseButton @click="goToNextElement" variant="secondary"
          >Next<template #rightIcon>
            <BaseIcon name="refresh" />
          </template>
        </BaseButton>
        <BaseButton @click="close" variant="secondary"
          >Close<template #rightIcon>
            <BaseIcon name="refresh" />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, watch } from 'vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import { onMounted } from 'vue';
import { computed } from 'vue';

import { useLocalStorage } from '@vueuse/core';

const props = defineProps<{
  items: Array<{ id: string; title: string }>;
  id: string;
}>();

const isVisible = ref(true);
const currentStep = ref(0);
const currentElement = ref<{
  top: number;
  left: number;
  height: number;
  width: number;
  name: string;
} | null>(null);

const scenarioStatus = useLocalStorage<Record<string, 'active' | 'finished'>>(
  'scenarioStatus',
  {}
);

const clipPath = computed(() => {
  if (!currentElement.value) return '';

  const { top, left, width, height } = currentElement.value;

  return `
    clip-path: polygon(
      0 0,
      100vw 0,
      100vw 100vh,
      0 100vh,
      0 0,
      0 ${top}px,
      ${left}px ${top}px,
      ${left}px ${top + height}px,
      ${left + width}px ${top + height}px,
      ${left + width}px ${top}px,
      ${left}px ${top}px,
      0 ${top}px
    );
  `;
});

const popupStyle = computed(() => {
  if (!currentElement.value) return {};

  const { top, left, width } = currentElement.value;

  const popupWidth = 350;
  const popupHeight = 150;
  const offset = 20;

  let popupLeft = left + width + offset;
  let popupTop = top;

  if (popupLeft + popupWidth > window.innerWidth) {
    popupLeft = left - popupWidth - offset;
  }

  if (popupTop + popupHeight > window.innerHeight) {
    popupTop = window.innerHeight - popupHeight - offset;
  }

  return {
    top: `${popupTop}px`,
    left: `${popupLeft}px`,
  };
});

const updateExcludedElements = () => {
  const elements = props.items.map(
    ({ id }) => document.querySelector(`#${id}`)!
  );
  const firstElement = elements[currentStep.value]!;

  if (firstElement) {
    const rect = firstElement.getBoundingClientRect();

    currentElement.value = {
      name: firstElement.id,
      top: rect.top - 5,
      left: rect.left + window.scrollX,
      width: rect.width,
      height: rect.height + 10,
    };
  }
};

const close = () => {
  isVisible.value = false;
  setScenarioStatus(props.id, 'finished');
};

const isCurrentScenarioFinished = computed(() => {
  return scenarioStatus.value[props.id] === 'finished';
});

const goToNextElement = () => {
  if (props.items.length > currentStep.value + 1) {
    currentStep.value += 1;
  } else {
    close();
  }
};

function setScenarioStatus(id: string, status: 'active' | 'finished') {
  scenarioStatus.value[id] = status;
}

onMounted(() => {
  nextTick(() => {
    updateExcludedElements();
  });
});

watch(currentStep, () => {
  nextTick(() => {
    updateExcludedElements();
  });
});
</script>

<style lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  pointer-events: auto;
}

.excluded-block {
  position: absolute;
  z-index: 9999;
  pointer-events: auto;
  animation: pulse 2s infinite ease-in-out;
  pointer-events: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.8);
  }

  50% {
    box-shadow: 0 0 25px 10px rgba(255, 255, 255, 0.5);
  }

  100% {
    box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.8);
  }
}

.popup {
  position: absolute;
  background-color: white;
  padding: 10px;
  max-width: 350px;
  max-height: 150px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .popup__actions {
    display: flex;
    justify-content: end;
  }
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>

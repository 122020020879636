<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const statusChartVariants = {
  variant: {
    donut: 'status-chart--donut',
    bar: 'status-chart--bar',
  },
  size: {
    small: 'status-chart--small',
    large: 'status-chart--large',
  },
};

const classes = cva({ variants: statusChartVariants });

export type StatusChartProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

import {
  emptyStatusCodeGroups,
  type StatusCodeGroups,
} from '@monorepo/shared-model/src/aggregated-discovery';

import BaseTooltip from '../BaseTooltip/BaseTooltip.vue';

const props = withDefaults(
  defineProps<{
    variant?: StatusChartProps['variant'];
    size?: StatusChartProps['size'];
    statusCodes?: StatusCodeGroups;
  }>(),
  {
    variant: 'donut',
    size: 'small',
    statusCodes: () => emptyStatusCodeGroups(),
  }
);

const slots = useSlots();

const hasDefault = computed(() => !!slots.default);

const totalCalls = computed(() => {
  return Object.values(props.statusCodes).reduce((acc, value) => {
    return acc + value.total;
  }, 0);
});

const status200Percentage = computed(() => {
  return calculatePercentage(props.statusCodes['2xx'].total, totalCalls.value);
});

const status400Percentage = computed(() => {
  return calculatePercentage(props.statusCodes['4xx'].total, totalCalls.value);
});

const status500Percentage = computed(() => {
  return calculatePercentage(props.statusCodes['5xx'].total, totalCalls.value);
});

const computedStyles = computed(() => {
  if (totalCalls.value === 0) {
    return 'background-color: var(--color-fg-info)';
  }

  const successPercentage = status200Percentage.value;
  const warningPercentage = status400Percentage.value;
  const dangerPercentage = status500Percentage.value;

  const dangerStart = successPercentage;
  const warningStart = successPercentage + dangerPercentage;
  const neutralStart = successPercentage + dangerPercentage + warningPercentage;

  const gradientType =
    props.variant === 'donut' ? 'conic-gradient' : 'linear-gradient';

  const gradientRotation = props.variant === 'bar' ? '90deg,' : '';

  return `background: ${gradientType}(
        ${gradientRotation}
        var(--color-fg-success) 0% ${successPercentage}%,
        var(--color-fg-danger) ${dangerStart}% ${warningStart}%,
        var(--color-fg-warning) ${warningStart}% ${neutralStart}%,
        var(--color-fg-info) ${neutralStart}% 100%
    )`;
});

const computedWrapper = computed(() => {
  return hasDefault.value ? BaseTooltip : 'span';
});

function calculatePercentage(number: number, total: number) {
  if (total === 0) {
    return 0;
  }
  return parseFloat(((number / total) * 100).toFixed(2));
}
</script>

<template>
  <component
    :is="computedWrapper"
    class="status-chart"
    :class="classes({ variant, size })"
    placement="right-end"
  >
    <template #content>
      <slot />
    </template>
    <div class="status-chart__chart" :style="computedStyles"></div>
  </component>
</template>

<style scoped lang="scss">
@use 'sass:math';
@use '../../assets/styles/utils' as *;

.status-chart {
  $self: &;

  &--donut {
    #{$self}__chart {
      height: 1em;
      margin: 0;
      width: 1em;
      border-radius: 50%;
      position: relative;

      &::after {
        background-color: theme('color-bg-container');
        border-radius: 50%;
        content: '';
        display: block;
        height: 0.45em;
        left: 27.5%;
        position: absolute;
        top: 27.5%;
        width: 0.45em;
      }
    }

    &#{$self}--small {
      #{$self}__chart {
        font-size: $font-size-xlarge;
      }
    }

    &#{$self}--large {
      #{$self}__chart {
        font-size: $font-size-xxxlarge;
      }
    }
  }

  &--bar {
    height: 100%;
    width: 100%;

    &#{$self}--small {
      padding: $space-xxsmall 0;

      #{$self}__chart {
        border-radius: math.div($space-xxsmall, 2);
        height: $space-xxsmall;
        width: 100%;
      }
    }

    &#{$self}--large {
      padding: $space-xxxsmall 0;

      #{$self}__chart {
        border-radius: math.div($space-small, 2);
        height: $space-small;
        width: 100%;
      }
    }
  }
}
</style>
